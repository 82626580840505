import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, List, InfoIcon } from '../components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const WarningIcon = makeShortcode("WarningIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`SmartVault supports three forms of authentication: OAuth 2.0 framework with a static key, Autonomous authentication using a public/private key pair, and pin-based authentication.`}<br /><br />{`
`}<em parentName="p">{`Each form of authentication pertains to a specific SmartVault API client type (Autonomous, OAuth, Pin).`}</em>{` `}<br /><br />{`
You can get more information about the authorization methods on the `}<a parentName="p" {...{
        "href": "/api/before-you-begin"
      }}>{`before you begin docs`}</a>{`.`}<br /><br />{`
`}<strong parentName="p">{`Both the OAuth 2 and Autonomous client types use the OAuth 2.0 Authorization framework.`}</strong><br /><br /></p>
    <p>{`OAuth 2.0`}</p>
    <p>{`Throughout the authorization documentation, you'll notice three kinds of tokens mentioned: `}</p>
    <ul>
      <li parentName="ul">{`the client token, `}</li>
      <li parentName="ul">{`authorization token, `}</li>
      <li parentName="ul">{`and the delegation token.`}<br /><br />{`
In most cases, you will use the delegation token to authenticate. For `}<a parentName="li" {...{
          "href": "/api/user-accounts/introduction"
        }}>{`user management`}</a>{` and
retrieving a delegation token (more info on each specific section of `}<a parentName="li" {...{
          "href": "/api/authorization/autonomous#request-a-delegation-token"
        }}>{`autonomous authentication`}</a>{`
and `}<a parentName="li" {...{
          "href": "/api/authorization/pin#request-a-delegation-token"
        }}>{`pin based authentication`}</a>{`), however, you will instead use the client token.`}</li>
    </ul>
    <br />
    <br />
    <h2 {...{
      "id": "client-token"
    }}>{`Client Token`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`The `}<strong parentName="p">{`client token`}</strong>{` is specific to the `}<em parentName="p">{`Autonomous`}</em>{` client type and is used to retrieve the delegation token.`}<br /><br />{`
This is a temporary token which can be used by an application to perform administrative tasks.`}<br /><br /></p>
    <p>{`To request a client token, you will need a self-signed token that uniquely identifies your application so that the SmartVault service can recognize it.
After redeeming your self-signed token, SmartVault will hand you the client token and an expiration (generally 24 hours).`}<br /><br /></p>
    <p>{`Once you are in possession of a client token, you can use it to log on as your client program.
You can also exchange this token for a delegation token.`}<br /></p>
    <InfoIcon mdxType="InfoIcon" />
Client tokens will be unique to a user and should be stored securely.
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "authorization-token"
    }}>{`Authorization Token`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`The `}<strong parentName="p">{`authorization token`}</strong>{` is returned via a redirect URI parameter.  It is a token that proves an end-user has started to OAuth authentication flow.`}<br /><br /></p>
    <p>{`This `}<strong parentName="p">{`one time use`}</strong>{` token can be exchanged for a delegation token such that the holder of the delegation token can perform actions on behalf of the end-user.`}<br /><br /></p>
    <InfoIcon mdxType="InfoIcon" />
Remember that the authorization token is single-use.
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "delegation-token"
    }}>{`Delegation Token`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`The delegation token is the token that "knows" that you are logged in, allowing you to access the account.`}<br />{`
Delegation tokens grant an application access to perform operations on behalf of a SmartVault user.`}<br /><br /></p>
    <p>{`There are two ways to obtain a delegation token.  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`OAuth 2.0 Flow:`}</strong><ul parentName="li">
          <li parentName="ul">{`Obtain a delegation token by exchanging an `}<strong parentName="li">{`authorization token`}</strong>{`.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Autonomous Flow:`}</strong><ul parentName="li">
          <li parentName="ul">{`Obtain a delegation token by redemming the `}<strong parentName="li">{`client token`}</strong>{` you receive after the user authorizes your application.`}<br /><br /></li>
        </ul></li>
    </ul>
    <p>{`Upon successful request of a delegation token, you will receive the delegation token, the token exipry in seconds, and the user Id.  Delegation token lifetime is 14 days.
You can obtain a delegation token by redeeming the client token you receive after the user authorizes your application.`}<br /><br /></p>
    <p>{`If a call is made to SmartVault’s API with an invalid token or expired token, the response will be a 401 status code.`}<br /></p>
    <br />
Sample error response.
    <br />
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "error": {
        "success": false,
        "error_code": -1593835519,
        "error_text": "Access Denied. Your rights may have changed; please Sign out and Sign back in to correct this problem.",
        "instance_guid": "22fff6c3-90eb-4b18-867d-bd44f65ce332",
        "status_code": 401
    }
}
`}</code></pre>
    <br />
    <InfoIcon mdxType="InfoIcon" />
Remember that the delegation token will not work if the user has revoked an application's access, or if it has expired.
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "authorization-header"
    }}>{`Authorization Header`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`The delegation token can be used as a bearer token to authenticate requests. Therefore, you can formulate the Authorization Header as follows:`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`bearer Q0xJMDAAAAAAAAABUYDOL8O67z2B7oVbKs+V2x2nfGMx3GascjMPJxcGFxvOyg==
`}</code></pre>
    <br />
    <WarningIcon mdxType="WarningIcon" />
SmartVault has historically allowed the Basic Authorization Header. However, it is now deprecated and will be removed in a later release.
    <br />
    <p>{`Let's assume the following `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`user's email addres: 'testuser@smartvault.com'
delegation token received: 'Q0xJMDAAAAAAAAABUYDOL8O67z2B7oVbKs+V2x2nfGMx3GascjMPJxcGFxvOyg=='
`}</code></pre>
    <p>{`Build a string as stated above using the user, a colon and the delegation token like: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`testuser@smartvault.com:Q0xJMDAAAAAAAAABUYDOL8O67z2B7oVbKs+V2x2nfGMx3GascjMPJxcGFxvOyg== 
`}</code></pre>
    <p>{`Then, get the Base64 representation of it, and that would be the token for the "Authorization" request header. e.g.: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ== 
`}</code></pre>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      